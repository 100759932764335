@import "../../styles/_base/_variables";

#page404 {
  display: grid;
  place-items: center;
  height: 100vh;
  padding: $space-medium;

  .content {
    max-width: 480px;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto; } } }
