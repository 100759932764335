.template {
	display: grid;
	grid-template-columns: none;
	grid-template-rows: 1fr $navigation-bar-height;
	grid-template-areas: "page-container" "navbar";
	min-height: 100%; } // for Safari

.navigation-bar {
	grid-area: navbar; }

.page-container {
	grid-area: page-container;
	display: flex;
	justify-content: center; }

.page {
	width: 100%; // otherwise the gird make it super narrow
	margin: 0 $space-medium;

	.header {
		max-width: 468px;
		margin-bottom: $space-small; }

	.title {
		max-width: 320px;
		margin-bottom: $space-small;
		box-sizing: border-box;
		padding-left: $space-medium;
		border-left: $space-small solid;
		border-color: $color-brand; }

	.description {
		color: $color-placeholder; } }

@media ( min-width: #{$media-desktop}) and (orientation: landscape) {
	.template {
		grid-template-columns: $navigation-bar-width 1fr;
		grid-template-rows: none;
		grid-template-areas: "navbar page-container"; }

	.page {
		position: relative;
		box-sizing: border-box;
		max-width: 960px;
		margin: 0 $space-big;
		padding-top: $space-big;

		.header {
			margin-bottom: $space-big; }

		.title {
			margin-bottom: $space-big; } } }


