h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  user-select: none;
  cursor: default; }

h1 {
  margin-bottom: $space-big;
  color: $color-text-primary;
  font-size: 34px; }

h2 {
  margin-bottom: $space-medium;
  color: $color-text-tertiary;
  font-family: $font-text;
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase; }

h3 {
  margin-bottom: $space-small;
  color: $color-text-primary;
  font-size: 17px;
  font-weight: bold; }

@media ( min-width: #{$media-desktop}) {
  h1 {
    font-size: 48px; }

  h2 {
    font-size: 24px; }

  h3 {
    font-size: 21px; } }
