.item-navigation {
  text-align: center;
  text-decoration: none;

  .icon {
    margin-bottom: 4px;
    font-size: 18px; }

  .label {
    text-decoration: none; } }

@media ( min-width: #{$media-tablet}) {
  .item-navigation {
    margin-bottom: $space-big;

    .icon {
      $size: 32px;
      margin: 0 auto;
      width: $size;
      height: $size;
      line-height: $size;
      border-radius: $border-radius;
      margin-bottom: 4px;
      font-size: 21px; }

    .label {
      font-size: $text-small; } }

  .item-navigation.selected {
    .icon {
      background-color: $color-brand;
      color: white; }
    .label {
      color: $color-text-secondary; } } }
