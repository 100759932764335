@import "styles/_base/_variables";

#course-list {
    .course-links {
        padding: 0;

        li {
            list-style-type: none;
            margin-bottom: $space-medium; }

        p {
            display: inline-block;
            margin: 0;
            color: $color-text-secondary; }

        img {
            border-radius: $space-smaller;
            width: 56px;
            margin-right: $space-small;
            vertical-align: middle; } } }
