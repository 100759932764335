@font-face {
  font-family: "Untitled Sans";
  src: url("../assets/fonts/untitled-sans-test-bold.woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Untitled Sans";
  src: url("../assets/fonts/untitled-sans-test-regular.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Fira Code";
  src: url("../assets/fonts/FiraCode-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}
