// Includes Sign up, Login and Recover password
.dual-hero {
  color: $color-text-tertiary;
  min-height: 100vh;

  .column {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $space-medium; }

  .column-hero {
    display: none; }

  .column-form {
    form {
      margin: 0 auto; }

    label {
      color: $color-text-primary; } }

  .logo {
    display: block;
    margin: 0 auto $space-big;
    width: 64px; } }

@media ( min-width: #{$media-tablet}) {
  .dual-hero {
    .column {
      min-height: 100vh; } // to center content on ipad vertical

    .logo {
      width: 96px; } } }

@media ( min-width: #{$media-tablet}) and (orientation: landscape) {
  .dual-hero {
    .column-form {
      form {
        max-width: 50%; } } } }

@media ( min-width: #{$media-desktop}) and (orientation: landscape) {
  .dual-hero {
    display: flex;

    .column {
      padding: $space-big;
      width: 50%; }

    .column-hero {
      display: flex;
      background-color: $color-background;

      .hero {
        max-width: 496px;
        text-align: center; }
      img {
        height: 300px; }

      p {
        max-width: 320px;
        margin: 0 auto; } }

    .column-form {
      form {
        max-width: 305px; } } } }
