.grid-container {
    display: grid;
    column-gap: $space-medium;
    grid-template-columns: repeat(1, 1fr); }

@media ( min-width: #{$media-tablet}) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr); } }

@media ( min-width: #{$media-desktop}) and (orientation: landscape) {
    .grid-container {
        column-gap: $space-big;
        grid-template-columns: repeat(4, 1fr); } }
