.button {
  $height: 44px;
  vertical-align: middle; // for anchor tags to align to button tags
  box-sizing: border-box;
  display: inline-block;
  height: $height;
  padding: 0 $space-big;
  background-color: $color-brand;
  color: white;
  line-height: $height;
  border: none;
  border-radius: $border-radius;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: $text-label;
  user-select: none;
  -webkit-appearance: none; } // to disable the round glossy legacy design of iOS

.button:hover {
  background-color: darken($color-brand, 15%);
  text-decoration: none; }

.button:disabled {
  background-color: $color-background;
  color: $color-placeholder;
  cursor: default; }

@media ( min-width: #{$media-desktop}) {
  .button {
    $height: 48px;

    min-width: 152px;
    height: $height;
    line-height: $height;
    font-size: $text-label-desktop; } }
