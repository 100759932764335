@import "styles/_base/_swatches";
@import "styles/_base/_variables";

#course-detail {
  .row-item {
    td:first-child {
      width: 56px; }

    td:last-child {
      width: 120px; }

    fieldset {
      $background-color: white;

      margin: 0;

      label {
        display: none; }

      select,
      input {
        background-color: $background-color;
        border-radius: 0;
        border: none; }

      .icon {
        background-color: $background-color; }

      input:focus,
      input:hover {
        background-color: $color-background;
        color: $color-text-primary; } } } }
