@import "../../styles/_base/_variables";

#video-playlist {
  .description {
    color: $color-text-tertiary; }

  .column-video {
    margin-bottom: $space-big; } } // when in mobile to avoid touching the other column in vertical

@media ( min-width: #{$media-desktop}) and (orientation: landscape) {
  $footer-height: 66px + 24px + 24px;// footer height + footer margin bottom + page padding top

  #video-playlist {
    .page {
      display: grid;
      grid-template-columns: 4fr 2fr;
      grid-template-rows: 1fr auto;
      grid-template-areas: "column-video column-list" "footer footer";
      column-gap: $space-big; }

    .column {
      max-height: calc(100vh - #{$footer-height});
      overflow: scroll; }

    .column-video {
      margin-bottom: 0; // reset the margin done
      grid-area: column-video; }

    .title {
      margin-bottom: $space-medium; }

    .column-list {
      box-sizing: border-box;
      padding: $space-medium 0;
      background-color: $color-background;
      grid-area: column-list;

      h2 {
        margin-left: $space-small; } }

    .footer {
      grid-area: footer; } } }
