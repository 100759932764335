@import "styles/_base/_swatches";
@import "styles/_base/_variables";

#course-detail {
  table {
    border-collapse: collapse;

    th, td {
      box-sizing: border-box;
      padding: $space-smaller; }

    th {
      font-weight: normal;
      text-transform: uppercase;
      color: $color-text-tertiary;
      font-size: $text-small;
      text-align: left; }

    td {
      padding: 0;
      border: solid 1px $color-border;
      text-align: center; } } }
