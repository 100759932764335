fieldset {
  border: none;
  margin-bottom: $space-medium;
  padding: 0;

  select,
  input {
    box-sizing: border-box;
    width: 100%;
    min-height: 44px;
    margin: 0;
    padding: $space-small;
    background-color: $color-background;
    font-size: $text-label;
    border-radius: $border-radius;
    border: solid 1px $color-border; }

  select:focus,
  input:focus {
    border: solid 2px $color-brand;
    outline: none; }

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 4px; }

  select,
  input {
    -webkit-appearance: none; } // to remove iOS style

  // For select
  .select-wrapper {
    position: relative; }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    box-sizing: border-box;
    margin-right: $space-small;
    padding: $space-small;
    padding-right: 0;
    background-color: $color-background;
    font-size: $text-label;
    pointer-events: none; } // to make the icon non clickable otherwise it would not allow the list to expand

  select::after {
    content: "<>";
    font: 17px "Consolas", monospace;
    color: red; } }


@media ( min-width: #{$media-desktop}) {
  fieldset {
    select,
    input {
      font-size: $text-label; } } }
