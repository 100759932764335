@import "styles/_base/_variables";

#dashboard-student {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); }

  @media ( min-width: #{$media-tablet}) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr); } }

  @media ( min-width: #{$media-desktop}) and (orientation: landscape) {
    .grid-container {
      grid-template-columns: repeat(4, 1fr); } } }
