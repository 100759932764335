@import "./_swatches";

// Border radius
$border-radius: 4px;

// Color
// ## Brand
$color-brand: #1E68FC;
// ## Layout
$color-background: $swatch-gray-light;
$color-border: $swatch-gray-medium;
$color-placeholder: $swatch-gray-medium-shade;
// ## Text
$color-text-primary: black;
$color-text-secondary: $swatch-gray-dark-shade;
$color-text-tertiary: $swatch-gray-dark;
// ## Courses
$color-programming-foundations: $swatch-red;
$color-software-engineering: $swatch-orange;
$color-individual-project: $swatch-yellow;
$color-programming-in-depth: $swatch-green;
$color-front-end-development: $swatch-cyan;
$color-back-end-development: $swatch-blue;
$color-group-project: $swatch-purple-shade;
// ## Material Files
$color-document: $swatch-cyan;
$color-slide: $swatch-orange;
$color-video: $swatch-red;
$color-workshop: $swatch-purple-shade;
$color-coding: $swatch-yellow;
$color-evaluation: $swatch-green;

// Fonts
$font-text: "Untitled Sans", Arial, Helvetica, sans-serif;
$font-code: "Fira Code", "Courier New", Courier, monospace;

// Media Queries
$media-phablet: 400px;
$media-tablet: 550px;
$media-desktop: 770px; // modified
$media-desktop-hd: 1500px; // modified

// Spacing
$space-smaller: 4px;
$space-small: 8px;
$space-medium: 16px;
$space-big: 24px;
$space-bigger: 48px;

// Text
$text-small: 12px;
$text-label: 15px;
$text-label-desktop: 17px;

// Key elements
$navigation-bar-height: 56px;
$navigation-bar-width: 72px;

// Snipets of media queries
// @media ( min-width: #{$media-phablet})
// @media ( min-width: #{$media-tablet})
// @media ( min-width: #{$media-desktop})
// @media ( min-width: #{$media-desktop-hd})
