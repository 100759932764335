.item-material {
  display: flex;
  align-items: center;
  margin-bottom: $space-medium;
  color: $color-text-secondary;
  text-decoration: none;
  overflow: hidden;

  .icon {
    $size: 32px;
    flex-shrink: 0;
    width: $size;
    height: $size;
    line-height: $size;
    margin-right: $space-medium;
    background-color: $color-brand;
    color: white;
    font-size: 16px;
    text-align: center;
    border-radius: $space-small; }

  small {
    color: $color-text-tertiary; } }

// Dynamic colors
.item-material {
  .icon.color-document {
    background-color: $color-document; }
  .icon.color-slide {
    background-color: $color-slide; }
  .icon.color-video {
    background-color: $color-video; }
  .icon.color-workshop {
    background-color: $color-workshop; }
  .icon.color-coding {
    background-color: $color-coding; }
  .icon.color-evaluation {
    background-color: $color-evaluation; }
  .icon.color-lock {
    color: $color-placeholder;
    background-color: $color-background; } }

@media ( min-width: #{$media-tablet}) {
  .item-material {
    margin-bottom: $space-big;

    .icon {
      $size: 64px;
      position: relative; // for the item # on the top
      width: $size;
      height: $size;
      line-height: $size;
      font-size: 32px;
      border-radius: $space-medium; } } }
