.navigation-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: $color-background;
  color: $color-text-secondary;

  a {
    color: $color-text-secondary;
    text-decoration: none; }

  hr {
    display: none; }

  a.selected {
    color: $color-brand; }

  a:hover {
    color: $color-brand; }

  .logo {
    display: none; }

  .profile {
    .avatar {
      display: none; } } }

@media ( min-width: #{$media-desktop}) and (orientation: landscape) {
  .navigation-bar {
    $size-image: 44px;

    position: fixed;
    width: $navigation-bar-width;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: $space-big $space-medium;
    user-select: none;

    hr {
      display: block;
      margin: $space-medium; }

    .logo {
      display: initial; }

    .logo img {
      width: $size-image;
      height: $size-image; }

    .profile {
      margin-top: auto;
      margin-bottom: 0;

      .label,
      .icon {
        display: none; }

      .avatar {
        box-sizing: border-box;
        border: solid 3px $color-background;
        display: block; // to remove gap on the bottom
        width: $size-image;
        height: $size-image; } } }

  .profile:hover,
  .profile.selected {
    .avatar {
      border-color: $color-brand; } } }
