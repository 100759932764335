@import "./_swatches";
@import "./_variables";

// for Safari
html, body, #root, .App {
	height: 100%;
	min-height: 100%; }

body {
	margin: 0;
	padding: 0; }

.App {
	color: $color-text-primary;
	font-family: $font-text;
	font-size: $text-label;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

@media ( min-width: #{$media-desktop}) {
	.App {
		font-size: $text-label-desktop; } }

// Debug
.App.debug {
	background-color: lightgray;

	.page-container {
		background-color: gray; }

	// Phone (red)
	.page {
		background-color: $swatch-red; }

	// Phablet (orange)
	@media ( min-width: #{$media-phablet}) {
		.page {
			background-color: $swatch-orange; } }

	// Tablet (yellow)
	@media ( min-width: #{$media-tablet}) {
		.page {
			background-color: $swatch-yellow; } }

	// Laptop (green)
	@media ( min-width: #{$media-desktop}) {
		.page {
			background-color: $swatch-green; } }

	// Desktop (blue)
	@media ( min-width: #{$media-desktop-hd}) {
		.page {
			background-color: $swatch-blue; } } }
