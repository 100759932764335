@import "../../styles/_base/_variables";

#code-editor {
    .footer {
        text-align: center; }

    .code-description {
        code {
            color: $swatch-purple-shade; } }

    #react-codeflask-root {
        height: 320px;
        margin-bottom: 8px;
        background-color: $color-background;
        border: solid 1px $color-border;
        border-radius: $border-radius; }

    .codeflask {
        position: relative; // to avoid reflowing the parent container
        background: none; } // to use the parent as the color

    button {
        margin-bottom: $space-big; }

    .button-text {
        border: none;
        background: none;
        color: $color-brand;
        cursor: pointer;
        font-size: 16px;
        .icon {
            margin-right: $space-small; } } }
