$swatch-red: #ED5565;
$swatch-red-shade: #DA4453;
$swatch-orange: #FF8A3C;
$swatch-orange-shade: #E46713;
$swatch-yellow: #FFCE54;
$swatch-yellow-shade: #F6BB42;
$swatch-green: #A0D368;
$swatch-green-shade: #8CC052;
$swatch-cyan: #4FC0E9;
$swatch-cyan-shade: #3BAFDA;
$swatch-blue: #5D9CEC;
$swatch-blue-shade: #4A89DC;
$swatch-purple: #AC92ED;
$swatch-purple-shade: #967ADC;
$swatch-gray-light: #F5F7FA;
$swatch-gray-light-shade: #E6E9ED;
$swatch-gray-medium: #CCD1D9;
$swatch-gray-medium-shade: #AAB2BD;
$swatch-gray-dark: #656D78;
$swatch-gray-dark-shade: #434A54;
