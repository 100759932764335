.item-video {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 44px;
  margin: 0;
  margin-bottom: $space-small;
  padding: $space-small;
  border: none;
  background: none;
  text-align: left;
  font-size: $text-label;
  border-radius: $border-radius;

  .icon {
    margin-right: $space-small;
    color: $color-border;
    opacity: 0; }

  .duration {
    text-align: right;
    width: 64px;
    margin-left: auto;
    color: $color-placeholder; } }

.item-video:hover,
.item-video.active,
.item-video:focus {
  background-color: $swatch-gray-light-shade;
  outline: none;

  .icon {
    color: $color-brand;
    opacity: 1; } }

@media ( min-width: #{$media-desktop}) and (orientation: landscape) {
  .item-video {
    min-height: auto;
    border-radius: 0; } }

@media ( min-width: #{$media-desktop}) {
  .item-vide {
    font-size: $text-label-desktop; } }
