@import "styles/_base/_variables";

@media ( min-width: #{$media-tablet}) {
  #course-detail {
    .form-item {
      h4 {
        padding: $space-smaller;
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: normal;
        font-size: 12px;
        color: $color-text-tertiary; }

      form {
        display: flex;
        align-items: flex-end;

        fieldset {
          margin-bottom: 0; }

        .button {
          height: 44px; } } } } }
@media ( min-width: #{$media-desktop-hd}) {
  #course-detail {
    .form-item {
      max-width: 75%; } } }
