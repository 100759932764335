.card {
  // move to @include unstyle-button
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  text-align: left;

  margin-bottom: $space-big;
  user-select: none;
  cursor: default;

  .image-container {
    position: relative;
    margin-bottom: 4px;
    border-radius: $border-radius;
    overflow: hidden;

    .lock {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-background;
      text-align: center;
      color: $color-text-tertiary;

      .icon {
        font-size: 21px; } }

    .image {
      display: block; // to remove gap on the bottom
      max-width: 100%; } }

  .label {
    display: inline-block;
    max-width: 80px;
    color: $color-text-primary;
    font-size: $text-label;
    font-weight: bold; } }

.card:hover {
  cursor: pointer;
  .label {
    text-decoration: underline; } }


@media ( min-width: #{$media-desktop}) {
  .card {
    margin-bottom: $space-big;

    .image-container {
      margin-bottom: $space-small; }

    .label {
      font-size: $text-label-desktop; } } }


@media ( min-width: #{$media-desktop-hd}) {
  .card {
    margin-bottom: ($space-big *2); } }
