.course {
    border-left: solid 4px $color-brand;
    padding-left: $space-small; }

.course.color-programming-foundations {
    border-color: $color-programming-foundations; }

.course.color-software-engineering {
    border-color: $color-software-engineering; }

.course.color-programming-in-depth {
    border-color: $color-programming-in-depth; }

.course.color-front-end-development {
    border-color: $color-front-end-development; }

.course.color-back-end-development {
    border-color: $color-back-end-development; }

.course.color-individual-project {
    border-color: $color-individual-project; }

.course.color-group-project {
    border-color: $color-group-project; }
