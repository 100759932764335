@import "../../styles/_base/_variables";

#course {
  // flex to fix footer to the bottom
  .page {
    display: flex;
    flex-direction: column; }

  .footer {
    margin-top: auto; }

  .list-material {
    margin-bottom: $space-small; } }

@media ( min-width: #{$media-tablet}) and (orientation: landscape) {
  .list-material {
    display: grid;
    grid-template-columns: repeat(2,  1fr);
    column-gap: $space-medium; } }

@media ( min-width: #{$media-desktop}) and (orientation: landscape) {
  .list-material {
    grid-template-columns: repeat(3,  1fr);
    column-gap: $space-big; } }

@media ( min-width: #{$media-desktop-hd}) and (orientation: landscape) {
  .list-material {
    grid-template-columns: repeat(4,  1fr); } }


// Dynamic colors
#course {
  .title.color-programming-foundations {
    border-color: $color-programming-foundations; }
  .title.color-software-engineering {
    border-color: $color-software-engineering; }
  .title.color-programming-in-depth {
    border-color: $color-programming-in-depth; }
  .title.color-front-end-development {
    border-color: $color-front-end-development; }
  .title.color-back-end-development {
    border-color: $color-back-end-development; }
  .title.color-individual-project {
    border-color: $color-individual-project; }
  .title.color-group-project {
    border-color: $color-group-project; } }
