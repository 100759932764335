a {
  color: $color-brand;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

a .icon {
  margin-right: $space-small; }

.link-button {
  margin: 0;
  padding: 0;
  border: none;
  font-size: $text-label;
  color: $color-brand;
  background: none;
  cursor: pointer;
  user-select: none; }

@media ( min-width: #{$media-desktop}) {
  .link-button {
    font-size: $text-label-desktop; } }
